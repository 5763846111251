/* Global Variables */
:root {
  --background-color: #ffffff;
  --navbar-color: #ffffff;
  --gray-hover: #f3f3f3;
  --red-hover: #ffecec;
  --text-color: #1c1c1c;
  --secondary-text-color: #4F4F4F;
  --primary-color: #7FAC6E;
  --darker-primary-color: #6D9A5D;
  --light-gray: #a9a9a9;
  --border-color-light: #d3d3d3;
  --box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 10px;
  --box-shadow-hover: rgba(0, 0, 0, 0.35) 0px 2px 10px;
  --btn-important-shadow: rgba(78, 113, 65, .2) 0 -25px 18px -14px inset,rgba(78, 113, 65, .15) 0 1px 2px,rgba(78, 113, 65, .15) 0 2px 4px,rgba(78, 113, 65, .15) 0 4px 8px,rgba(78, 113, 65, .15) 0 8px 16px,rgba(78, 113, 65, .15) 0 16px 32px;;
  --btn-important-transition: box-shadow 0.3s ease, max-height 0.5s ease, padding 0.3s ease, max-height 0.3s ease-in-out;
  --btn-important-hover: rgba(78, 113, 65,.35) 0 -25px 18px -14px inset,rgba(78, 113, 65,.25) 0 1px 2px,rgba(78, 113, 65,.25) 0 2px 4px,rgba(78, 113, 65,.25) 0 4px 8px,rgba(78, 113, 65,.25) 0 8px 16px,rgba(78, 113, 65,.25) 0 16px 32px;
  --background-opacity: .05;
  --navbar-border: 1px solid rgb(28, 28, 28, .3);
  --dropdown-hover: #eeeeee;
  --severity-banner-shadow: 0 2px 5px rgba(0, 0, 0, 0.25);
}

/* Dark Theme */
[data-theme="dark"] {
  --background-opacity: .03;
  --root-color: #0e0e0efb;
  --background-color: #1f1f1f;
  --navbar-color: #1d1d1d;
  --gray-hover: #333333; 
  --red-hover: #7a4a4a; 
  --text-color: #cccccc;
  --secondary-text-color: #a7a7a7; 
  --primary-color: #7FAC6E;
  --darker-primary-color: #6D9A5D; 
  --light-gray: #707070; 
  --border-color-light: #444444; 
  --box-shadow: rgba(202, 202, 202, 0.09) 0px 2px 10px;
  --box-shadow-hover: rgba(158, 158, 158, 0.25) 0px 2px 10px;
  --btn-important-shadow: rgba(150, 193, 145, .15) 0 -25px 18px -14px inset,rgba(150, 193, 145, .10) 0 1px 2px,rgba(150, 193, 145, .10) 0 2px 4px,rgba(150, 193, 145, .10) 0 4px 8px,rgba(150, 193, 145, .10) 0 8px 16px,rgba(150, 193, 145, .10) 0 16px 32px;
  --btn-important-transition: box-shadow 0.3s ease, max-height 0.5s ease, padding 0.3s ease, max-height 0.3s ease-in-out;
  --btn-important-hover: rgba(100, 140, 95, .3) 0 -25px 18px -14px inset,rgba(100, 140, 95, .25) 0 1px 2px,rgba(100, 140, 95, .25) 0 2px 4px,rgba(100, 140, 95, .25) 0 4px 8px,rgba(100, 140, 95, .25) 0 8px 16px,rgba(100, 140, 95, .25) 0 16px 32px;
  --navbar-border: 1px solid rgba(105, 105, 105, 0.3);
  --dropdown-hover: #333333;
  --severity-banner-shadow: 0 2px 5px rgba(158, 158, 158, 0.4);
  --toastify-color-dark: #1f1f1f !important;
  --toastify-text-color-dark: #cccccc !important;
}

.root {
  background-color: var(--background-color);
}

body {
  background-color: var(--root-color);
  font-family: 'Poppins', sans-serif;
  color: var(--text-color);
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  overflow: auto;
  transition: background-color 0.3s ease, box-shadow 0.3s ease, transform 0.2s ease;
}

body::before {
  content: ""; 
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url('../images/gem-singlelogo.png');
  background-size: cover; 
  background-position: center; 
  background-repeat: repeat; 
  opacity: var(--background-opacity); 
  z-index: -1;
}

li {
  position: relative;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 30px;
  padding-right: 30px;
  background-color: var(--background-color);
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 20px;
  box-shadow: var(--box-shadow);
  transition: box-shadow 0.3s ease;
  border: 1px solid var(--border-color-light);
  width: 100%;
  max-width: 400px;
  overflow: hidden;
}

li:hover {
  box-shadow: var(--box-shadow-hover);
}

.search-results {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
}

.search-div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 7px;
}

.search-results-span {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 20px;
  box-shadow: var(--box-shadow);
  transition: box-shadow 0.3s ease;
  width: 100%;
  border: 1px solid var(--border-color-light);
}

.search-results-span:hover {
  box-shadow: var(--box-shadow-hover);
}

.severity {
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 4px 10px;
  font-weight: bold;
}

.status-icon {
  position: absolute;
  top: 10px;
  left: 10px;
  padding: 4px 10px;
  font-weight: bold;
}


.list-group {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  padding-bottom: 40px;
  width: 100%;
  gap: 5px;
}

.list-div {
  margin: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 90%;
}

.high-sev-tickets,
.medium-sev-tickets,
.low-sev-tickets {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 350px;
    gap: 5px;
}



.hide-text {
  white-space: nowrap;   
  overflow: hidden;   
  text-overflow: ellipsis;
  display: inline-block; 
  vertical-align: middle; 
  max-width: 60%;
}

.title-div {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.li-div {
  display: flex;
  flex-direction: column;
}

/* Other body styles */
.darkmode-div {
  display: flex;
  justify-content: center;
}

.spin {
  animation: spin 4s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}


h1, h2, h3, a {
  font-weight: 600;
  letter-spacing: 1px;
  color: var(--text-color);
  text-transform: capitalize;
  text-align: center;
  margin: 20px 5px;
  line-height: 1.2;
}

h1 {
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}

a {
  color: var(--primary-color); /* Brand color for links */
  text-decoration: none;
  transition: color 0.3s;
}

a:hover {
  color: var(--darker-primary-color); /* Darker green on hover */
}

p {
  font-weight: 300;
  color: var(--secondary-text-color); /* Softer gray for body text */
  letter-spacing: 0.5px;
  margin-bottom: 16px;
}

h3 {
  margin-top: 20px;
  margin-bottom: 10px;
}

img {
  max-width: 100%;
}


/* Container Styles */
.modal-content {
  background-color: var(--background-color);
  color: var(--text-color);
  border-color: var(--border-color-light);
  --bs-modal-footer-border-color: var(--border-color-light);
  --bs-modal-header-border-color: var(--border-color-light);
}

.chat-modal {
  border-radius: 8px;
}

.modal-dialog {
  margin-top: 50px;
}

.contact-info p {
  padding-top: 5px;
  padding-left: 20px;
  font-size: 1.1rem;
}

.close-modal-times {
  height: 30px;
  cursor: pointer;
}

.modal-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 30px;
  padding-right: 40px;
}

.modal-header h3 {
  font-weight: bold;
  padding-left: 20px;
  padding-top: 20px;
}

.ticket-header {
  background-color: none;
  justify-content: center;
}

.container {
  margin: auto;
  overflow: none;
}

.card {
  background-color: var(--background-color);
  max-height: 80vh;
  overflow-y: auto;
  border-radius: 10px;
  box-shadow: var(--box-shadow);
  transition: box-shadow 0.3s ease, max-height 0.5s ease, padding 0.3s ease, max-height 0.3s ease-in-out;
}

.icon-div {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 7px;
}

.page-div {
  padding-bottom: 80px;
  padding-top: 0px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.archive-button {
  display: flex;
  justify-content: center;
}

.create-ticket-div {
  padding-top: 25px;
  padding-bottom: 25px;
  background-color: var(--background-color);
  border-radius: 15px;
  box-shadow: var(--box-shadow);
  border: .5px solid var(--border-color-light);
  width: 95%;
  max-width: 600px;
  transition: box-shadow 0.5s ease;
}

.create-ticket-div:hover {
  box-shadow: var(--box-shadow-hover);
}

.filter-div {
  display: flex;
  justify-content: center;
}

.filter-guides {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.guides-result-div {
  background-color: var(--background-color);
  box-shadow: var(--box-shadow);
  border-radius: 20px;
  width: 100%;
  max-width: 747px;
  height: 100%;
  margin: 30px;
  margin-bottom: 70px;
  overflow: auto;
}

.guides-result-div-mobile {
  display: none;
}

.edit-info p {
  margin: 0px;
}

.my-tickets {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 30px;
  p {
    margin: 0;
  }
  h4 {
    margin: 0;
  }
}

.new-ticket {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

.new-ticket-admin {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
}

.change-div {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 50px;
  gap: 20px;
}

.main-div {
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Navbar Styles */
.custom-navbar {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  padding:0px 30px;
  box-shadow: var(--box-shadow);
  background-color: var(--navbar-color);
  border-bottom: var(--navbar-border)
}

.nav-items {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.navbar-brand {
  overflow: hidden;
}

.navbar-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15px 20px 5px;
  border: none;
  margin-top: 5px;
  box-shadow: none;
  border-radius: 20px;
  transition: background-color 0.1s ease, transform 0.1s ease;
}

.navbar-item:hover {
  cursor: pointer;
  background-color: var(--gray-hover);
 }

 .navbar-logout:hover {
  cursor: pointer;
  background-color: var(--red-hover);
 }

 .navbar-item:active {
  scale: .95;
 }

 .navbar-home {
  margin-bottom: -2px;
 }

 .navbar-home-div {
  margin-right: 5px;
 }

 .react-icon {
  color: var(--text-color)
 }

 .react-icon:hover {
  cursor: pointer;
  transform: scale(1.07);
 }

.react-icon:active {
  transform: scale(.95);
}

.react-icon:focus {
  outline: none;
}

.image-eye {
  margin: 0px 0px 7px;
  opacity: .5;
}

.backup-navbar {
  display: none;
}

.profile-navbar {
  margin-top: 70px;
  padding-bottom: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 60px;
}

.backup-profile-navbar {
  display: none;
}

.settings-navbar {
  margin-top: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 60px;
  padding-bottom: 30px;
}

.backup-settings-navbar {
  display: none;
}

.register-navbar {
  margin-top: 70px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 60px;
  padding-bottom: 30px;
}

.backup-register-navbar {
  display: none;
}

/* Form Styles */

form input, form textarea, form select {
  display: block;
  margin: 10px auto;
  width: 200px;
  padding: 8px;
  overflow-y: auto;
}

textarea.form-control::placeholder, input.form-control::placeholder, select.form-select, .form-control, input, select, textarea {
  font-family: inherit;
  color: var(--text-color);
  caret-color: var(--text-color);
  background-color: var(--background-color);
  border-color: var(--border-color-light);
}

input.imageUpload.form-control {
  background-color: var(--background-color);
}

.form-control:focus {
  border-color: var(--primary-color) !important;
  color: var(--text-color);
  background-color: var(--background-color);
  box-shadow: none !important;
}

.form-select:focus {
  border-color: var(--primary-color) !important;
  box-shadow: none !important;
}

option:hover {
  background-color: green !important;
}

.type-div {
  width: 80%;
  max-width: 500px;
}

.input-div {
  width: 100%
}

.pass-form {
  max-width: 400px;
}

.input-box {
  padding: 10px 15px;
  margin-top: 7px;
  margin-bottom: 7px;
  background-color: var(--background-color);
  color: var(--text-color);
  border-radius: 15px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 2px 10px;
  border: .5px solid var(--border-color-light);
}

.input-box-email {
  padding: 10px 15px;
  margin-top: 7px;
  margin-bottom: 7px;
  background-color: var(--background-color);
  border-radius: 15px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 2px 10px;
  border: .5px solid var(--border-color-light);
  width: 80%;
}

.select-box {
  margin-top: 7px;
  margin-bottom: 7px;
  background-color: var(--background-color);
  border-radius: 15px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 2px 10px;
  transition: box-shadow 0.3s ease, max-height 0.5s ease, padding 0.3s ease, max-height 0.3s ease-in-out;
  border: .5px solid var(--border-color-light);
}

.select-box:hover {
  cursor: pointer;
}

.btn-important {
  background-color: var(--primary-color);
  border-radius: 30px;
  box-shadow: var(--btn-important-shadow);
  transition: var(--btn-important-transition);
  color: var(--background-color);
  cursor: pointer;
  display: inline-block;
  font-family: inherit;
  padding: 7px 20px;
  text-align: center;
  text-decoration: none;
  transition: all 250ms;
  border: 0;
  font-size: 16px;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  margin-top: 7px;
}

.btn-important:hover {
  box-shadow: var(--btn-important-hover);
  transform: scale(1.02);
}

.btn-important:active {
  transform: scale(0.95);
  box-shadow: none;
}

.btn-login {
  background-color: var(--primary-color);
  border-radius: 30px;
  box-shadow: var(--btn-important-shadow);
  transition: box-shadow 0.3s ease, max-height 0.5s ease, padding 0.3s ease, max-height 0.3s ease-in-out;
  color: var(--background-color);
  cursor: pointer;
  display: inline-block;
  font-family: inherit;
  padding: 7px 20px;
  text-align: center;
  text-decoration: none;
  transition: all 250ms;
  border: 0;
  font-size: 16px;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  margin-top: 7px;
}

.btn-login:hover {
  box-shadow: var(--btn-important-hover);
  transform: scale(1.02);
}

.btn-login:active {
  transform: scale(0.95);
  box-shadow: none;
}

.btn-2 {
  background-color: var(--background-color);
  border-radius: 30px;
  box-shadow: rgba(78, 113, 65, .15) 0 1px 2px,rgba(78, 113, 65, .15) 0 2px 4px,rgba(78, 113, 65, .15) 0 4px 8px,rgba(78, 113, 65, .15) 0 8px 16px,rgba(78, 113, 65, .15) 0 16px 32px;
  transition: box-shadow 0.3s ease, max-height 0.5s ease, padding 0.3s ease, max-height 0.3s ease-in-out;
  color: var(--primary-color);
  cursor: pointer;
  display: inline-block;
  font-family: inherit;
  padding: 5px 18px;
  text-align: center;
  text-decoration: none;
  transition: all 250ms;
  border-color: var(--primary-color);
  border-style: solid;
  font-size: 16px;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  margin-top: 7px;
}



.btn-2:hover {
  box-shadow: rgba(78, 113, 65,.25) 0 1px 2px,rgba(78, 113, 65,.25) 0 2px 4px,rgba(78, 113, 65,.25) 0 4px 8px,rgba(78, 113, 65,.25) 0 8px 16px,rgba(78, 113, 65,.25) 0 16px 32px;
  border-color: var(--primary-color);
  transform: scale(1.02);
}

.btn-2:active {
  transform: scale(0.95);
  border-color: var(--primary-color);
  box-shadow: none;
}

.input-form-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.image-upload {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 5px;
  margin-bottom: 5px;
  background-color: var(--background-color);
  border-radius: 15px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 2px 10px;
  border: 0.5px solid var(--border-color-light);
  color: var(--text-color);
  max-width: 225px;
  cursor: pointer;
}

.file-name {
  opacity: .5;
  margin-bottom: 5px;
}

.search-container {
  position: relative; 
  display: flex;
  align-items: center; 
}

.archive-modal-button {
  position: absolute;
  left: 100%;
  margin-left: 10px; 
  top: 58%; 
  transform: translateY(-50%); 
  z-index: 10;
}

.archive-button-2 {
  cursor: pointer;
  font-size: 1.2rem; 
  color: var(--text-color);
}

.archive-button-2:hover {
  transform: scale(1.08);
}

.archive-button-2:focus {
  outline: none;
}

.icon {
  color: var(--text-color);
  border: none;
  background-color: transparent;
}

.icon:hover {
  transform: scale(1.09);
}


.register-icon {
  width: 45px;
  height: 45px;
  margin-top: 10px;
  overflow: visible;
}

.download-image {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.download-image a {
  color: var(--text-color);
  margin: 10px 0px 5px;
}

.url-image {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.url-image p {
  margin: 0px 0px 7px;
  opacity: .5;
}

.uploaded-image-div {
  border: 1px solid var(--border-color-light);
  padding: 10px;
  margin: 10px;
}

.delete-button-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 10px;
}

.delete-confirmation {
  display: flex;
  gap: 15px;
}

/* List Group Styles */
.li-header {
  font-family: inherit;
  font-weight: bold;
  font-size: 1.6rem;
  padding: 10px;
}

.badge {
  font-size: 0.9rem;
  margin-top: 5px;
}

/* Change Password Styles */
.change {
  flex: 1;
  padding: 20px;
  background-color: var(--background-color);
  border-radius: 15px;
  box-shadow: var(--box-shadow);
  transition: box-shadow 0.3s ease, max-height 0.5s ease, padding 0.3s ease, max-height 0.3s ease-in-out;
  border: .5px solid var(--border-color-light);
  width: 100%;
  max-width: 400px;
}

.change:hover {
  box-shadow: var(--box-shadow-hover);
}

/* My Info Styles */
.my-info {
  padding: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  p {
    margin: 0;
  }
  h4 {
    margin: 0;
  }
}

/* Background Styles */
.background {
  background-color: var(--background-color);
}

/* Toast Styles */
.custom-toast-container .Toastify__toast {
  opacity: 0.8;
  background-color: var(--text-color);
  color: var(--text-color);
  border-radius: 8px;
}

.Toastify__toast--success {
  background-color: rgba(40, 167, 69, 0.9);
  color: white;
}

.Toastify__toast--error {
  background-color: rgba(220, 53, 69, 0.9);
  color: white;
}

/* Responsive Icon Styles */
.home-page-icon {
  color: var(--text-color);
  width: 35px;
  height: 35px;
  cursor: pointer;
  background-color: transparent;
}

.home-page-icon:hover {
  transform: scale(1.07);
}

.home-page-icon:active {
  transform: scale(.95);
}

.home-page:hover {
  transform: scale(1.07);
}

.profile-icon {
  margin-top: 10px;
  width: 40px;
  height: 40px;
  cursor: pointer;
  background-color: transparent;
  overflow: visible;
}

.profile-icon:hover {
  transform: scale(1.07);
}

.responsive-settings-icon {
  width: 50px;
  height: 50px;
  overflow: visible;
}

.responsive-chat-icon {
  width: 51px;
  height: 51px;
  cursor: pointer;
  transition: transform 0.2s ease;
  color: var(--text-color)
}

.responsive-chat-icon:hover {
  transform: scale(1.07);
}

.responsive-chat-icon:active {
  transform: scale(.95);
}

.severity-banner {
  position: absolute;
  top: 50px;
  right: -20px;
  font-size: 16px;
  border-radius: 5px;
  transform: rotate(45deg); 
  transform-origin: top right;
  height: 15px;
  width: 100px; 
  box-shadow: var(--severity-banner-shadow);
}

.severity-banner.Low {
  background-color: rgba(127, 172, 110, .7);
}

.severity-banner.Medium {
  background-color: rgba(243, 184, 67, 0.7);
}

.severity-banner.High {
  background-color: rgba(233, 56, 56, 0.7);
}

.react-tooltip {
  background-color: var(--text-color) !important;
  color: var(--background-color) !important;
  padding: 3px 6px 2px !important;
}

.static-status {
  text-align: center;
  margin-top: 5px;
  margin-bottom: 5px;
  background-color: var(--background-color);
  border-radius: 15px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 2px 10px;
  border: 0.5px solid var(--border-color-light);
  opacity: 0.8;
  color: var(--light-gray);
  max-width: 150px;
}

.static-status:hover {
  cursor: not-allowed;
}

.ticket-status-numbers {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}


.card {
  border: 1px solid var(--border-color-light);
  border-radius: 8px;
  overflow: hidden;
  margin-bottom: 10px;
  box-shadow: var(--box-shadow);
  transition: box-shadow 0.3s ease, max-height 0.5s ease, padding 0.3s ease, max-height 0.3s ease-in-out;
  cursor: pointer;
}

.card.active {
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
}

.card-header {
  background-color: var(--navbar-color);
  padding: 15px;
  font-weight: 500;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--text-color)
}

.collapsible-card-body {
  cursor: default;

  p {
    cursor: text;
  }

  svg {
    cursor: pointer;
  }
}

.card-body {
  max-height: 0;
  padding: 0 15px;
  overflow: hidden;
  transition: max-height 0.5s ease-in-out, padding 0.3s ease-in-out;
}

.card.active .card-body {
  max-height: 100%;
  height: 100%;
  padding: 15px;
}

.card.active {
  box-shadow: var(--box-shadow)
}

.open-card {
  max-height: 100%;
  height: auto;
}

.card:hover {
  scale: 1.002;
  box-shadow: var(--box-shadow-hover);
}

.edit-user-div {
  margin-top: 0px;
  margin-bottom: 20px;
}

.edit-user-label {
  margin-bottom: 0px;
}

.login-div {
  display: grid;
  place-items: center;
  align-content: center;
  margin-top: 20px;
}

.register-card {
  border: 1px solid var(--border-color-light);
  background-color: var(--background-color);
  border-radius: 15px;
  margin-top: 10px;
  padding: 20px 40px 20px;
  margin-bottom: 5rem;
  box-shadow: var(--box-shadow);
  transition: box-shadow 0.3s ease, max-height 0.5s ease, padding 0.3s ease, max-height 0.3s ease-in-out;
}

.register-div {
  display: flex;
  flex-direction: row;
  align-items: top;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  gap: 15px;
  margin-top: 20px;
  padding-bottom: 3rem;
}

.register-admin-card {
  border: 1px solid var(--border-color-light);
  background-color: var(--background-color);
  border-radius: 8px;
  overflow-y: auto;

  padding: 30px;
  box-shadow: var(--box-shadow);
  transition: box-shadow 0.3s ease, max-height 0.5s ease, padding 0.3s ease, max-height 0.3s ease-in-out;
}

.register-card:hover {
  box-shadow: var(--box-shadow-hover);
}

.register-admin-card:hover {
  box-shadow: var(--box-shadow-hover);
}

.form-select-boxes {
  max-width: 225px;
}

.static-status {
  text-align: center;
  margin-top: 5px;
  margin-bottom: 5px;
  background-color: var(--background-color);
  border-radius: 15px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 2px 10px;
  border: 0.5px solid var(--border-color-light);
  opacity: 0.8;
  color: var(--light-gray);
  max-width: 150px;
}

.add-to-sheets {
  margin: 20px 0 0 10px;
  
}

.dropdown-item:active {
  background-color: var(--primary-color);
}

.dropdown-menu {
  background-color: var(--background-color);
  border-color: var(--border-color-light);
}

.dropdown-item {
  color: var(--text-color);
}

.dropdown-menu .dropdown-divider {
  --bs-dropdown-divider-bg: var(--border-color-light); /* Set your desired color */
}

.dropdown-item:hover {
  background-color: var(--dropdown-hover);
  color: var(--text-color);
}


/* Media Queries */

@media screen and (max-width: 995px) {
  .form-select-boxes {
    max-width: 225px;
  }
  .static-status {
    text-align: center;
    margin-top: 5px;
    margin-bottom: 5px;
    background-color: var(--background-color);
    border-radius: 15px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 2px 10px;
    border: 0.5px solid var(--border-color-light);
    opacity: 0.8;
    color: var(--light-gray);
    max-width: 150px;
  }

}

@media screen and (max-width: 768px) {
  .form-select-boxes {
    width: 100%;
    max-width: 180px;
  }

  .static-status {
    text-align: center;
    margin-top: 5px;
    margin-bottom: 5px;
    background-color: var(--background-color);
    border-radius: 15px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 2px 10px;
    border: 0.5px solid var(--border-color-light);
    opacity: 0.8;
    color: var(--light-gray);
    max-width: 150px;
  }
}

@media screen and (max-width: 940px) {
  .custom-navbar {
    display: none;
  }

  .backup-navbar {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    padding: 30px;
    box-shadow: var(--box-shadow);
    background-color: var(--navbar-color);
    border: .5px solid var(--border-color-light);
  }
}


@media screen and (max-width: 630px) {
  .responsive-chat-icon {
    width: 41px;
    height: 41px;
    cursor: pointer;
}

  h2 {
      font-size: 1.5rem;
  }

  .home-page-icon {
    width: 35px;
    height: 35px;
    cursor: pointer;
    background-color: transparent;
  }

}

.img-1 {
  display: block;
  margin-top: 5rem;
}

.img-2 {
  display: none;
}

.loader-wrapper {
  display: flex;                   
  justify-content: center;          
  align-items: center;              
  height: 80vh;                      
}

.loader-wrapper-2 {
  display: flex;                   
  justify-content: center;          
  align-items: center;
  margin: 20px;                                 
}

.loader-wrapper-3 {
  display: flex;                   
  justify-content: center;          
  align-items: center;
  height: 50vh;                                
}

.lds-ellipsis,
.lds-ellipsis div {
  box-sizing: border-box;
}
.lds-ellipsis {
  display: inline-block;
  justify-content: center;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ellipsis div {
  position: absolute;
  top: 33.33333px;
  width: 13.33333px;
  height: 13.33333px;
  border-radius: 50%;
  background: currentColor;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}

@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}

@media screen and (max-width: 600px) {
  .img-1 {
      display: none;
  }

  .img-2 {
      display: block;
  }
}

@media screen and (max-width: 775px) {
  .profile-navbar {
    display: none;
  }

  .backup-profile-navbar {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 50px;
    margin-bottom: 30px;
  }

  .settings-navbar {
    display: none;
  }

  .backup-settings-navbar {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 50px;
    padding-bottom: 5px;
  }

  .register-navbar {
    display: none;
  }

  .backup-register-navbar {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 50px;
    padding-bottom: 30px;
  }

  }

  @media screen and (max-width: 760px) {
    .change-div {
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    .change {
      width: 100%;
    }
  }

  @media screen and (max-width: 770px) {
    .guides-result-div {
      display: none;
    }
    
    .guides-result-div-mobile {
      display: block;
      margin:10px;
    }
  }